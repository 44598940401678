import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';

const SpringLink = ({ to, children }) => (
  <TransitionLink
    to={to}
    exit={{
      length: 1.5,
    }}
    entry={{ length: 1, delay: 1.5 }}
  >
    {children}
  </TransitionLink>
);

export default SpringLink;
