import React, { useState } from 'react';
import styled from 'styled-components';

import ListTitle from './ListTitle';

const Wrapper = styled.div`
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0 0 80px;
`;

const AnimList = ({ mount, children, data: { sako }, transitionStatus , ...props }) => {
  const [animTitle, setAnimTitle] = useState('');

  // const projects = sako.projects;
  const projects = sako.projects.sort((a, b) => (a.order < b.order) ? 1 : -1);

  return (
    <Wrapper>
      <ListTitle
        key={'calendar' + projects.length + 1}
        index={projects.length + 1}
        name={sako.calendarPages[0].title.toUpperCase()}
        url={'calendar'}
        setAnimTitle={title => setAnimTitle(title)}
        animTitle={animTitle}
        mount={mount}
        pop={transitionStatus === 'POP'}
        transitionStatus={transitionStatus}
        entered = {transitionStatus === 'entered' || transitionStatus === 'POP'}
      />
      {projects.map((item, i) => {
        return (
          <ListTitle
            key={item.title + i}
            index={i + 1}
            name={item.title.toUpperCase()}
            url={item.slug}
            setAnimTitle={title => setAnimTitle(title)}
            animTitle={animTitle}
            mount={mount}
            pop={transitionStatus === 'POP'}
            transitionStatus={transitionStatus}
            entered = { transitionStatus === 'entered' || transitionStatus === 'POP'}
          />
        );
      })}
      <ListTitle
        key={'about0'}
        index={0}
        name={sako.aboutPages[0].title.toUpperCase()}
        url={'about'}
        setAnimTitle={title => setAnimTitle(title)}
        animTitle={animTitle}
        mount={mount}
        pop={transitionStatus === 'POP'}
        transitionStatus={transitionStatus}
        entered = { transitionStatus === 'entered' || transitionStatus === 'POP'}
      />
    </Wrapper>
  );
};

export default AnimList;
