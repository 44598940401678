import PropTypes from 'prop-types';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { TransitionState } from 'gatsby-plugin-transition-link';

import { Container } from './layoutComponents';
import Title from './shared/Title';

const StyledHeader = styled.header`
  height: 50px;
  transition: opacity 0.15s ease-in-out;
  opacity: ${({ mounted }) => (mounted ? '1' : '0')};
  position: relative;
  width: 100%;
  z-index: 2;
  
  @media (min-width: 800px) {
    margin: 0 0 0 20px;
  }
`;

const MarqueeWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const marquee = keyframes`
  from {
    transform: translateX(100vw);
  }
  to {
    transform: translateX(-100%);
  }
`;

const StyledDiv = styled.div`
  display: inline;
  color: inherit;
`;

const ScrollingText = styled.div`
  display: inline-block;
  font-size: 21px;
  line-height: 1;
  font-weight: initial;
  margin: 0;
  padding: 10px 0;
  animation: ${marquee} 26s linear infinite;
  animation-duration: ${({textDur}) => textDur/1.25}s;
  transform: translateX(100vw);
  color: ${({saver}) => saver ? '#fff' : '#000'};

  @media (min-width: 800px) {
    animation-duration: ${({textDur}) => textDur}s;
  }

  &:hover {
    animation-play-state: paused;
  }

  p,
  a,
  div {
    display: inline;
    color: inherit;
  }

  a {
    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const Header = ({ mount, upcoming, transitionStatus, textDur, saver }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  return (
    <TransitionState>
      {({ mount }) => {
        return (
          <StyledHeader mounted={mount || transitionStatus === 'POP'}>
            <Container>
              {!isTabletOrMobile && <Title saver={saver} shadow={true} inFooter={false}>Paweł Sakowicz</Title>}
              <MarqueeWrapper>
                <ScrollingText textDur={textDur} saver={saver}>
                  {upcoming.title && ' ' + upcoming.title}:
                  {upcoming.link &&
                    upcoming.link.map((item, i) => (
                      <StyledDiv key={'header' + i} dangerouslySetInnerHTML={{ __html: ' ' + item.html }}></StyledDiv>
                    ))}
                </ScrollingText>
              </MarqueeWrapper>
            </Container>
          </StyledHeader>
        );
      }}
    </TransitionState>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
