import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { easeCubicIn, easeExpIn } from 'd3-ease';

import SpringLink from './SpringLink';

const Title = styled(animated.h2)`
  color: black;
  font-size: 14.5vw;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: 800px) {
    font-size: 100px;
  }

  a {
    color: inherit;
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: transform 0.2s ease-in-out, width 0.2s ease-in-out;

    /* @media (min-width: 800px) {
      &:hover {
        width: ${({ hover }) => (hover !== null ? 100 : 106)}%;
        transform: ${({ hover }) => (hover !== null ? 'translateX(0%)' : 'translateX(-3%)')};
      }
    } */
  }
`;

const ListTitle = ({ name, url, index, setAnimTitle, animTitle, mount, pop, entered, transitionStatus }) => {
  const [fromTop, setFromTop] = useState(0);
  const [width, setWidth] = useState(0);
  const [hover, setHover] = useState(null);
  const itemRef = useRef(null);

  const handleClick = (e, name) => {
    setHover(false);
    setFromTop(itemRef.current.getBoundingClientRect().top);
    setAnimTitle(name);
  };

  const renderName = name => {
    const splitName = name.split('');
    let newName = [];

    splitName.forEach((item, i) => {
      newName.push(<span key={item + i}>{item}</span>);
    });

    return newName;
  };

  useEffect(() => {
    setWidth(itemRef.current.offsetWidth);
  }, []);

  const positionAnim = useSpring({
    transform: `translateY(${mount || pop ? '0px' : '-' + (fromTop - 22) + 'px'})`,
    delay: 700,
    config: {
      duration: 600,
      easing: easeCubicIn
    }
  });


  const margins = typeof window !== 'undefined' && window.innerWidth < 800 ? 30 : 40;

  const widthAnim = useSpring({
    width: `${mount || pop ? width + 'px' : document.documentElement.clientWidth - margins + 'px'}`,
    config: {
      duration: 500,
      easing: easeCubicIn
    }
  });

  // const hide = useSpring({
  //   opacity: `${mount ? 1 : 0}`,
  //   config: {
  //     duration: 150,
  //     easing: easeCubicIn
  //   }
  // });

  
  const hide = useSpring({
    opacity: `${entered ? 1 : 0}`,
    config: {
      duration: 600,
      easing: easeExpIn
    }
  });


  return (
    <Title
      index={index + 1}
      // style={animTitle === name ? { ...widthAnim, ...positionAnim } : entered ? show : hide}
      style={animTitle === name ? { ...widthAnim, ...positionAnim } : (transitionStatus === 'entering' || transitionStatus === 'entered') && (typeof window !== 'undefined' && window.innerWidth < 800) ? {} : hide }
      onClick={e => handleClick(e, name)}
      hover={hover}
      ref={itemRef}
    >
      <SpringLink to={`/${url}`}>{renderName(name)}</SpringLink>
    </Title>
  );
};

export default ListTitle;
