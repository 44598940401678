import React, {useState, useEffect} from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import TransContainer from '../components/TransContainer';
import Header from '../components/header';
import { Container } from '../components/layoutComponents';
import SEO from '../components/seo';
import ProjectsList from '../components/ProjectsList';
import ScreenSaver from '../components/ScreenSaver';

const IndexPage = props => {
  const [saver, setSaver] = useState(false);
  const saverDelay = 30000;

  let saverTimer = setTimeout(() => null);

  const handleClick = () => {
    setSaver(false);
    clearTimeout(saverTimer);

    if (typeof window !== 'undefined' && window.innerWidth >= 800) {
      saverTimer = setTimeout(() => setSaver(true), saverDelay)
    }
  }

  const handleMove = () => {

    if (!saver && typeof window !== 'undefined' && window.innerWidth >= 800) {
      handleClick();
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth >= 800) {
      saverTimer = setTimeout(() => setSaver(true), saverDelay);
    }

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('mousemove', handleMove);
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('mousemove', handleMove);
    };

  }, [saver]);

  return (
    <StaticQuery
      query={graphql`
        query {
          sako {
            upcomings {
              title
              link {
                html
              }
            }
            aboutPages {
              title
            }
            calendarPages {
              title
            }
            projects {
              title
              slug
              order
            }
            generals {
              headerTextDuration
              screenSaver {
                url
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Layout saver={saver} showCard={true} showName={false} transitionStatus={props.transitionStatus}>
            <TransContainer transitionStatus={props.transitionStatus}>
              <Header saver={saver} transitionStatus={props.transitionStatus} upcoming={data.sako.upcomings[0]} textDur={data.sako.generals[0].headerTextDuration} />
            </TransContainer>
            <Container>
              <SEO title='Paweł Sakowicz' keywords={[`Paweł Sakowicz`, `choreography`, `dance`]} />
              <ProjectsList transitionStatus={props.transitionStatus} data={data} />
              {saver && (
                <ScreenSaver screenSaver={data.sako.generals[0].screenSaver} />
              )}
            </Container>
          </Layout>
        );
      }}
    />
  );
};

export default IndexPage;
