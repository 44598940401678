import React from 'react';
import { TransitionState } from 'gatsby-plugin-transition-link';
import AnimList from '../components/AnimList';

const ProjectsList = ({ mount, children, data }) => {
  return (
    <TransitionState>
      {(props) => {
        return (
          <AnimList {...props} data={data} />
        );
      }}
    </TransitionState>
  );
};

export default ProjectsList;
