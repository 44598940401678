import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-image: url(${({bgImg}) => bgImg});
  background-size: cover;
  background-position: center;
`;

const ScreenSaver = ({screenSaver}) => {
  return (
    <Wrapper bgImg={screenSaver.url}>screen</Wrapper>
  )
}

export default ScreenSaver;